import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import {
  ShellBar,
  Button,
  Popover,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  StandardListItem,
} from '@ui5/webcomponents-react';
import { getAvatar } from '../plugins/profile.plugin';
import logo from './logo';
import LogoutForm from '../plugins/LogoutForm';
import CustomNotification from './CustomNotification';
import UserProfile from './UserProfile';
import { withTranslation, eventBus } from 'src/common/eureka';

const StartButton = ({ slot, onClick }) => (
  <Button slot={slot} icon={'menu2'} design="Transparent" onClick={onClick} />
);

class Header extends Component {
  static propTypes = {
    common: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
  };

  state = {
    menuBtnVisible: true,
  };

  notificationPopoverRef = createRef();
  profileMenuRef = createRef();

  componentDidMount() {
    // this.props.actions.startWebsocket();
    eventBus.on('toggle-menu-btn-visible', () => {
      this.setState({ menuBtnVisible: !this.state.menuBtnVisible });
    });
  }

  componentWillUnmount() {
    eventBus.off('toggle-menu-btn-visible');
  }

  handleProfileClick = (e) => {
    this.profileMenuRef.current.showAt(e.detail.targetRef);
  };

  openProfileSetting = (e) => {
    this.closeProfileMenu();

    let { config, t } = this.props;
    eventBus.emit('dialog-show-hide', '', {
      type: 'user-profile',
      data: {
        showDialog: true,
        title: t('ProfileSettings'),
        content: () => <UserProfile config={config} eventBus={eventBus} />,
      },
    });
  };

  logout = () => {
    this.closeProfileMenu();
    eventBus.emit('dialog-show-hide', '', {
      type: 'user-logout',
      data: {
        showDialog: true,
        content: <LogoutForm eventBus={eventBus} />,
      },
    });
  };

  closeProfileMenu = () => {
    this.profileMenuRef.current.close();
  };

  handleSidenav = () => {
    const { showMenu } = this.props.common;
    showMenu ? this.props.actions.hideMenu() : this.props.actions.showMenu();
    eventBus.emit('toggle-side-menu');
  };

  handleNotificationClick = (e) => {
    this.closeProfileMenu();
    this.notificationPopoverRef.current.showAt(e.detail.targetRef);
  };

  dismissNotification = (notification) => {
    this.props.actions.dismissNotification(notification);
  };

  handleShowMoreClick = (event, notification) => {
    event.preventDefault();
    this.props.actions.dismissNotification(notification);
    this.props.history.push(event.target.href);
  };

  render() {
    const { notification } = this.props.common ?? { notification: [] };
    const { t } = this.props;
    const { menuBtnVisible } = this.state;
    const filteredNotifications = notification.filter(
      (item) => item.data.businessObject !== 'RETURN_DETAIL_UPDATE',
    );
    const sortedNotifications = [...filteredNotifications].sort((item1, item2) => {
      let date1 = new Date(item1.receivedTime).getTime();
      let date2 = new Date(item2.receivedTime).getTime();

      return date2 - date1;
    });

    const hasNotifications = sortedNotifications.length > 0;

    return (
      <>
        <ShellBar
          id="eureka-shellbar"
          logo={<img alt="SAP Logo" style={{ maxHeight: '2rem' }} src={logo} />}
          startButton={menuBtnVisible ? <StartButton onClick={this.handleSidenav} /> : null}
          primaryTitle={t('AppName')}
          profile={getAvatar(this.props.user)}
          onProfileClick={this.handleProfileClick}
          showNotifications
          notificationsCount={sortedNotifications.length}
          onNotificationsClick={this.handleNotificationClick}
        ></ShellBar>

        <Popover
          ref={this.profileMenuRef}
          allowTargetOverlap
          horizontalAlign={PopoverHorizontalAlign.Center}
          placementType={PopoverPlacementType.Bottom}
          preventFocusRestore={false}
        >
          <div>
            <StandardListItem
              data-key="1"
              onClick={this.openProfileSetting}
              icon={'user-settings'}
              infoState="None"
              selected={false}
              type="Active"
            >
              {t('ProfileSettings')}
            </StandardListItem>
            <StandardListItem
              data-key="2"
              onClick={this.logout}
              infoState="None"
              icon={'log'}
              selected={false}
              type="Active"
            >
              {t('Logout')}
            </StandardListItem>
          </div>
        </Popover>

        <Popover
          ref={this.notificationPopoverRef}
          allowTargetOverlap
          horizontalAlign={PopoverHorizontalAlign.Center}
          placementType={PopoverPlacementType.Bottom}
        >
          <div
            style={{
              width: hasNotifications ? '410px' : '110px',
              padding: '10px',
              maxHeight: '400px',
              overflow: 'auto',
              overflowX: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                whiteSpace: 'nowrap',
                color: 'var(--sapTextColor)',
              }}
            >
              {hasNotifications
                ? sortedNotifications.slice(0, 66).map((notification) => {
                    return (
                      <CustomNotification
                        key={'notification_' + notification.id}
                        notification={notification}
                        onCloseFunction={this.dismissNotification}
                        settings={this.props.settings}
                        onClickFunction={this.handleShowMoreClick}
                      />
                    );
                  })
                : t('Header_Notification_Empty')}
            </div>
          </div>
        </Popover>
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    common: state.common,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
